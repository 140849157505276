.app{}

.cup-container{
  position: relative;
  height: 7rem;
  width:4rem;
  
}

.cup-left{
  height: 6rem;
  width: 3rem;
  border-top: .4rem solid darkgreen;
  background-color: green;
  transform: skew(7deg);
  border-bottom-left-radius:1rem;
  position:absolute;
  float: left;
}

.cup-right{
  height: 6rem;
  width: 3rem;
  border-top: .4rem solid darkgreen;
  background-color: green;
  transform: skew(-7deg);
  border-bottom-right-radius:1rem;
  
  float: right;
}

.cup-left-removed{
  height: 6rem;
  width: 3rem;
  border-top: .4rem solid darkred;
  background-color: red;
  transform: skew(7deg);
  border-bottom-left-radius:1rem;
  position:absolute;
  float: left;
}

.cup-right-removed{
  height: 6rem;
  width: 3rem;
  border-top: .4rem solid darkred;
  background-color: red;
  transform: skew(-7deg);
  border-bottom-right-radius:1rem;
  
  float: right;
}


.home-card{
  text-align: center;
  background-color: #80475E;
  color:#fff;
  transform: skew(-15deg);
  width: 80vw;
  height: 8rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.title{
  font-size: 4rem;
  border:1px solid black;
  height: 100%;
  padding-top: 2rem;
}

.join-instructions{
  color: #000;
  font-size: 1rem;
}

.input{
   transform: skew(-15deg);
}

.button-container{
  text-align: center;
}
.button{
  transform: skew(-15deg);
}
.answer-button{
  margin:8px;
}
.button-bottom{
  transform: skew(-15deg);
  text-align: center;
  margin-top: 1rem;
}
.input-container{
}

.card{
  margin: auto;
  margin-top: 4rem;
  justify-content:space-evenly;
  border:2px solid grey;
  box-shadow:1px 1px grey;
  height: 25rem;
  width: 80vw;
}

.score-title{
  font-size: 1.25rem;
  text-decoration: double;
}
.videoContainer{
  height: 100%;
  width: 100%;
  margin: auto;
  padding:2px;
  position: relative;
}

.otherVideo{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
}
.video{
  position: absolute; 
  width: 35%;
  top: 0;
  right: 0;
  /* padding-right: 10px; */
  /* left:10; */
  z-index: 10;
}

.error{
  color:red;
}